//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  > a {
    color: $gray-700;
  }
  + .breadcrumb-item {
    &::before {
      font-family: 'Material Design Icons';
    }
  }
}

ul.main-breadcrumb {
  display: inline-block;
  list-style: none;
  //   height: 70px;
  //   line-height: 70px;
  padding: 0;
  color: $breadcrumb-item-active;
}

ul.main-breadcrumb li {
  display: inline-block;
  font-size: 16px;
}

ul.main-breadcrumb li + li:before {
  padding: 10px;
  color: grey;
  content: '/';
}

ul.main-breadcrumb li a {
  cursor: pointer;
  color: $breadcrumb-item !important;
  text-decoration: none;
}

ul.main-breadcrumb li a:hover {
  color: $breadcrumb-item-hover !important;
}
