.designage-data-table-wrapper {
  border-radius: $border-radius;
  border: 1px solid $border-color-accordion-tables !important;
  overflow: auto;
  height: auto;
  max-height: 100%;
}
.mat-mdc-table {
  width: 100%;
  background-color: $bg-content-container-dark !important;
  font-family: $font-family-base !important;
  color: $text-dark;
  td {
    border-top: 1px solid $table-border-color;
    padding: 0.75rem;
  }
  th {
    font-weight: $font-weight-semibold;
    font-family: $font-family-secondary;
  }
  margin: 0;

  .mat-column-select,
  .mat-column-deleteRow,
  .mat-column-statusIndicator {
    flex: none;
    width: 3rem !important;
    padding-left: 0.25rem !important;
  }
  .mat-column-deleteRow {
    color: grey;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    &:hover {
      color: $danger;
    }
  }

  .mat-column-read,
  .mat-column-write {
    flex: none;
    width: 20%;
    justify-content: center;
    padding: 0;
  }

  mat-row,
  mat-header-row,
  mat-footer-row {
    display: flex;
    border-width: 0;
    border-style: solid;
    align-items: center;
    box-sizing: border-box;
    color: $text-dark !important;
  }
  .row-selected {
    background-color: #384b5f !important;
    &:hover {
      background-color: #202a35 !important;
    }
  }
  .row-in-sliding-panel {
    background-color: $form-select-color !important;
  }

  mat-row:hover {
    background-color: #30373e;
    cursor: pointer !important;
  }
  mat-header-row,
  mat-footer-row {
    min-height: 2.75rem;
    background-color: $bg-playlist-sequence-header;
    mat-header-cell {
      padding-left: 1rem;
      padding-right: 1rem;
      border-bottom: 1px solid $border-color-accordion-tables !important;
      color: $text-dark;
      font-weight: 800;
      vertical-align: middle;
      font-family: $font-family-base !important;
    }
    mat-footer-cell {
      padding-left: 1rem;
      padding-right: 1rem;
      border-top: 1px solid $border-color-accordion-tables !important;
      color: $text-dark;
      font-weight: 400;
      vertical-align: middle;
      font-family: $font-family-base !important;
    }
  }
  mat-row:not(:last-child) {
    border-bottom: 1px solid rgba(112, 119, 128, 0.5) !important;
  }
  mat-row {
    mat-cell:not(:first-child) {
      border-left: 1px solid rgba(112, 119, 128, 0.5) !important;
    }
    vertical-align: middle;
    height: 48px;
    &.cdk-drag-disabled {
      cursor: default;
    }
  }

  mat-cell {
    padding-left: 1rem;
    padding-right: 0.5rem;
    border-top: unset;
    color: $text-dark;
    font-weight: 600;
    font-size: 0.8rem;
    font-family: $font-family-base !important;
    .badge {
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
  mat-cell:first-of-type,
  mat-header-cell:first-of-type,
  mat-footer-cell:first-of-type {
    padding-left: 1rem;
    align-items: center;
  }
  mat-cell:last-of-type,
  mat-header-cell:last-of-type,
  mat-footer-cell:last-of-type {
    padding-right: 0.5rem;
  }
}

mat-row.cdk-drag-preview {
  mat-cell {
    padding-left: 1rem;
    padding-right: 0.5rem;
    color: $text-dark;
    font-family: $font-family-base !important;
    background-color: $bg-playlist-card !important;
  }
  -webkit-box-shadow: 1px 1px 5px 0px rgba(37, 37, 37, 1);
  -moz-box-shadow: 1px 1px 5px 0px rgba(37, 37, 37, 1);
  box-shadow: 1px 1px 5px 0px rgba(37, 37, 37, 1);
  border: 1px solid $bg-content-container-dark;
  overflow: hidden;
  border-radius: $border-radius;
}
mat-row.cdk-drag-placeholder {
  border: 1px solid $bg-content-container-dark;
  overflow: hidden;
  border-radius: $border-radius;
  .mat-mdc-cell {
    padding-left: 1rem;
    padding-right: 0.5rem;
    border-top: unset;
    color: $text-dark !important;
    font-family: $font-family-base !important;
    background-color: $bg-playlist-card !important;
  }
}

mat-row.cdk-drag-disabled {
  cursor: not-allowed;
}

.mat-column-name {
  min-width: 10rem !important;
}
.mat-column-select {
  flex-grow: 0;
  min-width: 2.8rem !important;
}
.mat-column-region {
  flex-grow: 2;
}
[class*='mat-column'] {
  min-width: 5rem;
}

.datatable-device-array-popover {
  width: 30rem !important;
  max-width: 40vw !important;
  box-shadow: 0px 0px 20px 1px #202020 !important;
  max-height: 20rem;
  height: 100%;
  // overflow: hidden;
  .popover-body {
    height: inherit;
    padding: 0.25rem !important;
  }
  .popover-arrow::after {
    border-top-color: $white !important;
  }
  h3 {
    margin: 0;
  }
}

.image-preview-popover {
  width: 250px !important;
  height: 250px !important;
  img {
    width: 100%;
    height: 100%;
  }
}
.image-preview {
  .icon {
    width: 40px;
    height: 40px;
  }
}

.group-row {
  background-color: #2b3b4b !important;
}
