//
// dropdown.scss
//
.dropdown {
  text-align: left;
}
.dropdown-item {
  font-size: $dropdown-font-size !important;
}
.dropdown-menu {
  // background-color: $bg-content-container-dark;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  border: none;
  // font-size: $font-size-base;
  font-size: 0.75rem;
  position: absolute;
  z-index: 1000;

  &.show {
    top: 100% !important;
  }
  .ngb-dp-day {
    height: 1.75rem;
  }
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='left'] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(0px);
  }
}
// Dropdown Mega Menu

.dropdown-mega {
  position: static !important;
}

.dropdown-megamenu {
  padding: 20px;
  left: 20px !important;
  right: 20px !important;
  background-image: url('/libs/assets/images/megamenu-bg.png');
  background-position: right bottom;
  background-repeat: no-repeat;
}

.megamenu-list {
  li {
    padding: 5px 20px;
    position: relative;

    a {
      color: $gray-700;

      &:hover {
        color: $primary;
      }
    }

    &:before {
      content: '\F130';
      position: absolute;
      left: 0;
      font-family: 'Material Design Icons';
      font-size: 9px;
      line-height: 24px;
    }
  }
}
ngb-datepicker.dropdown-menu {
  background-color: white;
}
