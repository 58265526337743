//
// nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $text-standard;
      font-weight: $font-weight-semibold;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

.nav-tabs,
.nav-pills {
  border-bottom: 1px solid #495561 !important;
  border: none;

  > .nav-link {
    border: none;
  }

  > a {
    color: $text-dark;
  }

  > a.active {
    background-color: $component-active-bg !important;
    color: $component-active-color !important;
  }
}

//Navtab custom
.navtab-bg {
  li {
    > a {
      background-color: $navtab-bg;
      margin: 0 5px;
    }
  }
}

// Nav bordered

.nav-bordered {
  border-bottom: 2px solid rgba($gray-600, 0.2) !important;
  .nav-item {
    margin-bottom: -2px;
  }

  li {
    a {
      border: 0 !important;
      padding: 10px 20px !important;
    }
  }

  a.active {
    border-bottom: 2px solid $primary !important;
  }
}

.nav-link {
  cursor: pointer;
  background-color: $navtab-bg;
  padding: 0.2rem 0.3rem 0.2rem 0.3rem;
  color: $text-dark;
}

.nav-link:hover {
  color: $text-standard !important;
}

.tab-content {
  height: 100% !important;
  & > .tab-pane {
    height: 100% !important;
  }
}
