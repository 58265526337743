@use '@angular/material' as mat;
@import 'variables';

html {
  font-size: 18px !important;
}

@include mat.core();
$designage-primary: mat.define-palette($md-primary, 500);
$designage-accent: mat.define-palette($md-accent);
$designage-warn: mat.define-palette($md-warn);

$designage-theme: mat.define-light-theme(
  (
    color: (
      primary: $designage-primary,
      accent: $designage-accent,
      warn: $designage-warn,
    ),
  )
);
@include mat.all-component-themes($designage-theme);

//Fonts
@import 'custom/fonts/fonts';

//Core files

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins.scss';
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/topbar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
@import 'custom/structure/layouts';
@import 'custom/structure/content-container';

//Components
@import 'custom/components/modal';
@import 'custom/components/helper';
@import 'custom/components/social';
@import 'custom/components/widgets';
@import 'custom/components/custom-checkbox';
@import 'custom/components/custom-radio';
@import 'custom/components/ribbons';
@import 'custom/components/print';
@import 'custom/components/preloader';
@import 'custom/components/thumbnail';
@import 'custom/components/date-time-picker';
@import 'custom/components/ng-selector';
@import 'custom/components/map';
@import 'custom/components/slider';
@import 'custom/components/pagination';
@import 'custom/components/folderexplorer';
@import 'custom/components/designage-data-table';
@import 'custom/components/alerts';
@import 'custom/components/borders';
@import 'custom/components/accordions';
@import 'custom/components/badge';
@import 'custom/components/backgrounds';
@import 'custom/components/breadcrumb';
@import 'custom/components/buttons';
@import 'custom/components/card';
@import 'custom/components/images';
@import 'custom/components/dropdown';
@import 'custom/components/forms';
@import 'custom/components/modal';
@import 'custom/components/nav';
@import 'custom/components/pagination';
@import 'custom/components/popover';
@import 'custom/components/progress';
@import 'custom/components/reboot';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/as-split';

// Plugins
@import 'custom/plugins/waves';
@import 'custom/plugins/toaster';
@import 'custom/plugins/sweetalert';
@import 'custom/plugins/nestable-list';
@import 'custom/plugins/jstree';
@import 'custom/plugins/ion-rangeslider';
@import 'custom/plugins/bootstrap-tagsinput';
@import 'custom/plugins/bootstrap-select';
@import 'custom/plugins/multiple-select';
@import 'custom/plugins/select2';
@import 'custom/plugins/clockpicker';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/daterange';
@import 'custom/plugins/parsley';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/quilljs-editor';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/x-editable';
@import 'custom/plugins/datatable';
@import 'custom/plugins/tablesaw';
@import 'custom/plugins/responsive-table';
@import 'custom/plugins/footables';
@import 'custom/plugins/flot';
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/morris';
@import 'custom/plugins/c3';
@import 'custom/plugins/chartist';
@import 'custom/plugins/sparkline-chart';
@import 'custom/plugins/google-maps';
@import 'custom/plugins/vector-maps';
@import 'custom/plugins/mapeal-maps';
@import 'custom/plugins/calendar';
@import 'custom/plugins/hopscotch';

// Pages
@import 'custom/pages/logout';
@import 'custom/pages/error';
@import 'custom/pages/email';
@import 'custom/pages/taskboard';
@import 'custom/pages/coming-soon';
@import 'custom/pages/timeline';
@import 'custom/pages/faq';
@import 'custom/pages/pricing';
@import 'custom/pages/gallery';

body.inheritCursors * {
  cursor: inherit !important;
}

body,
html {
  font-size: 14px !important;
}

@media (min-width: 1280px) {
  body,
  html {
    font-size: 16px !important;
  }
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.icon-appear-on-hover {
  i {
    opacity: 0;
    transition: opacity 150ms ease;
  }
  &:hover {
    i {
      opacity: 1;
    }
  }
}

.hidden {
  visibility: hidden;
  height: 0;
}
.inspector-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem;
  .inspector-panel-header {
    display: flex;
    align-items: center;
    height: 2.25rem;
    border-bottom: 1px solid $border-light !important;
  }
}

.col-gap-1 {
  column-gap: 0.25rem;
}
.col-gap-2 {
  column-gap: 0.5rem;
}
.col-gap-3 {
  column-gap: 1rem;
}

.hover-fade {
  .hover-fade-target {
    opacity: 0;
    transition: opacity 150ms ease;
  }
  &:hover {
    .hover-fade-target {
      opacity: 100;
    }
  }
}

.max-vw-100 {
  max-width: 100vw;
}
.max-vh-100 {
  max-height: 100vw;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $as-gutter-color transparent;
}
*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: transparent;
  border-radius: 10px;
}
*::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: $as-gutter-color;
  border-radius: 10px;
}

.upload-container {
  position: absolute;
  z-index: 1;
  bottom: 25px;
  left: 25px;
}

.no-caret.dropdown-toggle::after {
  display: none !important;
}

hr {
  border-top: 1px solid #707780 !important;
}

.hidden {
  display: none;
}

ngb-pagination {
  .page-item.disabled .page-link {
    color: #9a9a9a;
    background-color: unset;
    border-color: transparent;
  }

  .page-item .page-link {
    color: #ededed;
    background-color: unset;
    border-color: transparent;
    border-radius: 0.2rem;
    margin: 0 3px;
    font-weight: 700;
    font-size: 13px;
    &:focus {
      outline: none;
    }
  }
  .page-item a:focus {
    outline: none;
  }
}

ngb-datepicker {
  background-color: white !important;
  .ngb-dp-day .btn-light:hover {
    background-color: $gray-100 !important;
  }
}

ngb-timepicker {
  .ngb-tp {
    justify-content: center;
  }
}

.ngbTooltipClass {
  max-width: 15rem;
  text-align: left;
  pointer-events: none;
}

.label-icon {
  margin-left: 6px;
}

.shadowed-box {
  -webkit-box-shadow: 1px 1px 5px 0px rgba(37, 37, 37, 1);
  -moz-box-shadow: 1px 1px 5px 0px rgba(37, 37, 37, 1);
  box-shadow: 1px 1px 5px 0px rgba(37, 37, 37, 1);
  // border: 1px solid $bg-content-container-dark;
}

.manage-sequence-container {
  margin-bottom: 10px;
}

.status-indicator {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: block;
}
.tooltip {
  .tooltip-inner {
    max-width: 100%;
    max-height: 100%;
    text-align: left;
    font-size: 1rem;
    background: #2a2a3b;
    box-shadow: 1px 1px 5px 0 #252525;
  }
}

.custom-tooltip .arrow::before {
  border-top-color: #3c4853 !important;
}

.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.sliding-panel-header {
  position: absolute;
  top: 0;
  left: 1rem;
  label {
    padding: 0.25rem;
    font-size: 2rem;
    font-weight: bold;
    font-family: $font-family-secondary;
  }
}

p {
  font-weight: $font-weight-light;
  line-height: 1.1rem;
}
