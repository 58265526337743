@import 'variables';

as-split {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  width: 100%;
  height: 100%;

  & > .as-split-gutter {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: transparent !important;
    position: relative !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 199;
    pointer-events: auto;

    &.as-split-gutter-collapsed {
      flex-basis: 1px !important;
      pointer-events: none;
    }

    & > .as-split-gutter-icon {
      width: 70% !important;
      height: 70% !important;
      border-radius: 3px;
      background-image: none !important;
      background-color: $as-gutter-color;
      // background-color: $designage-primary !important;
      transition: all 0.2s !important;
      position: absolute !important;
      opacity: 1;
    }
  }

  ::ng-deep > .as-split-area {
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    height: 100%;
    // overflow-y: auto;

    /* When <as-split-area [visible]="false"> force size to 0. */

    &.as-hidden {
      flex: 0 1 0px !important;
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  &.as-horizontal {
    flex-direction: row;

    & > .as-split-gutter {
      flex-direction: row;
      cursor: col-resize;
      height: 100%; // <- Fix safari bug about gutter height when direction is horizontal.

      & .as-split-gutter-icon {
        width: 1px !important;
      }
    }
    & > .as-split-gutter.as-dragged .as-split-gutter-icon,
    & > .as-split-gutter:hover > .as-split-gutter-icon {
      width: 6px !important;
    }

    // ::ng-deep > .as-split-area {
    //   height: 100%;
    //   overflow: hidden;
    // }
  }

  &.as-vertical {
    flex-direction: column;

    & > .as-split-gutter {
      flex-direction: column;
      cursor: row-resize;
      width: 100%;

      & .as-split-gutter-icon {
        height: 1px !important;
      }
    }
    & > .as-split-gutter.as-dragged .as-split-gutter-icon,
    & > .as-split-gutter:hover > .as-split-gutter-icon {
      height: 6px !important;
    }

    ::ng-deep > .as-split-area {
      width: 100%;

      &.as-hidden {
        max-width: 0;
      }
    }
  }

  /* When disabled remove gutters background image and specific cursor. */

  &.as-disabled {
    & > .as-split-gutter {
      cursor: default;

      .as-split-gutter-icon {
        background-image: none;
      }
    }
  }

  /* Add transition only when transition enabled + split initialized + not currently dragging. */

  &.as-transition.as-init:not(.as-dragging) {
    & > .as-split-gutter,
    ::ng-deep > .as-split-area {
      transition: flex-basis 0.3s;
    }
  }
}
