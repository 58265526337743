//
// modal.scss
//

.modal {
  background: #ffffff63;
}

.modal-body {
  p {
    white-space: pre-line;
  }
}
.modal-container {
  display: flex;
  flex-direction: column;
  color: $text-standard;
  overflow: auto;

  .modal-header {
    border-bottom: 1px solid $border-content-dark;
  }

  .modal-footer {
    border-top: 1px solid $border-content-dark;
  }
  .modal-content,
  .modal-body {
    // height: 70vh;
  }

  .close {
    color: $text-dark;
  }

  h1,
  h2,
  h3,
  h4 {
    color: $text-standard;
  }

  p {
    font-size: 16px;
    color: $text-standard;
  }

  p.danger {
    color: $text-danger;
  }
}

// Title text within header
.modal-title {
  margin-top: 0;
}

// Full modal
.modal-full {
  width: 92%;
  max-width: none;
}

// Custom animation modal
.modal-demo {
  background-color: $white;
  width: 600px !important;
  border-radius: 4px;
  display: none;
  position: relative;
  overflow: hidden;
  .close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: $light;
  }
}

@media (max-width: 768px) {
  .modal-demo {
    width: 96% !important;
  }
}

.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: $primary;
  color: $white;
  text-align: left;
  margin: 0;
}

.custom-modal-text {
  padding: 20px;
}

.custombox-modal-wrapper {
  text-align: left;
}

.custombox-modal-flash,
.custombox-modal-rotatedown {
  .close {
    top: 20px;
    z-index: 9999;
  }
}

.modal-dialog {
  overflow: hidden;
  border-radius: $modal-content-border-radius;
}

.modal-content {
  background-color: $bg-modal-dark;
}

.modal-lg {
  .modal-content {
    padding: 1rem;
  }
}

.custom-centered-modal .modal-dialog {
  margin-top: 0 !important;
  top: 50% !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.app-modal-xl .modal-dialog {
  max-width: 1140px;
}
.cesdk-modal {
  .modal-dialog {
    max-width: $modal-width-xl;
    margin: calc(calc(100vh - 99vh) / 2) auto;
  }
  .modal-content,
  .modal-container {
    width: $modal-width-xl;
    height: $modal-height-xl;
    creative-editor {
      height: 100%;
    }
    .modal-body {
      height: 100%;
      overflow: hidden;
    }
  }
}

.stat-modal {
  .modal-dialog {
    height: 100%;
    max-height: 90vh;
    overflow: hidden;
  }
  .modal-content {
    height: 100%;
    display: flex;
    .modal-body {
      height: 100%;
      overflow: auto;
    }
  }
}

.playlist-modal {
  .modal-dialog {
    max-width: $modal-playlist-width;
  }
  .modal-container {
    display: flex;
    flex-direction: column;
    width: $modal-playlist-width;
    height: $modal-playlist-height;
    .modal-body {
      height: 100%;
      overflow: hidden;
    }
  }
}

.btn-close:focus {
  box-shadow: none;
}
